import { ProductInterface } from '../../../../Interfaces/Product.ts';
import {
    PRODUCT_TYPE_ECARD,
    PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE,
    PRODUCT_TYPE_PHYSICAL_CARD,
    PRODUCT_TYPE_PRODUCT,
} from '../../../../Constant.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { Span } from '../../../Styled/ProductLayoutStyled.tsx';

type ProductTypeProps = {
    product: ProductInterface;
    classNames?: string[];
    display?: boolean;
};

export default function ProductType({ product, classNames = [], display }: ProductTypeProps) {
    const { t } = useSharedTranslation();
    const isVirtual = product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE;
    const isPhysical = !isVirtual && (product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD || product.productType?.name === PRODUCT_TYPE_PRODUCT);
    const isDivisible = product?.productDivisibleChoice?.isDivisible;
    const isCumulative = product?.cumulative;

    const classes = classNames.join(' ');

    return (
        <>
            {isVirtual && <Span className={classes}>Virtuel</Span>}
            {isPhysical && <Span className={classes}>Physique</Span>}
            {!display && classes != 'cart' && (
                <>
                    <Span>{isCumulative ? t('product.products.cumulative') : t('product.products.notcumulative')}</Span>
                    <Span>{isDivisible ? t('product.products.divisible') : t('product.products.notdivisible')}</Span>
                </>
            )}
        </>
    );
}
